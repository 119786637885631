import { WIDGET_TYPE_INLINE } from "@components-core/ExternalWidget";
import { connectHOCs } from "@components-utils";
import {
  PRODUCT_SELECTOR_TYPE_SEARCH_RESULT,
  SE_OPTION_RELEVANCE_HIGH,
  SE_TAG_SEARCHBOX_ONLY
} from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  searchSuggest,
  searchSuggestFailure,
  searchSuggestSuccess
} from "@redux-actions/search";
import { getSuggestOptions } from "@redux-actions/utils";
import { matchBreakpoints, mediaBreakpoint } from "@utils/breakpoints";
import React from "react";
import { Dropdown, FormControl, InputGroup } from "react-bootstrap";
import Layout from "../Layout/Layout";
import SearchEngine from "./SearchEngine";

/**
 * @description Wrapper for programmable Google Search Engine plugin.
 *  Supports search input/results in same window, in separate windows,
 *  overlay and within same modal popup.
 *
 * @export
 * @see https://support.google.com/programmable-search/answer/4513882?hl=en
 * @class GoogleSearchEngine
 * @extends {SearchEngine}
 */
class SearchEngineCustom extends SearchEngine {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      suggestResultVisibility: null
    };

    this.refDropdownMenu = React.createRef();

    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);

    this.suggestTimer = null;

    this.defaultSuggestOptions = getSuggestOptions();
  }
  /**
   * @inheritdoc
   * @memberof SearchEngineCustom
   */
  getDefaultSearchOptions() {
    return {
      exactMatch: false,
      relevance: SE_OPTION_RELEVANCE_HIGH
    };
  }

  /**
   * @inheritdoc
   * @memberof SearchEngineCustom
   */
  onToggled(toggled) {
    super.onToggled(true);
  }

  /**
   * @description Redirect to the search result page
   * @param {String} searchText The searched text
   * @param {Object} options The search matching options
   * @memberof SearchEngineCustom
   */
  redirectToResultPage(searchText, options) {
    const query = {
      [this.props.queryParameterName]: searchText,
      e: options.exactMatch,
      r: options.relevance
    };

    const redirectPath = this.props.pathfinder.routeQuery(
      this.props.resultPage,
      {},
      Object.keys(query)
        .filter(key => "undefined" !== typeof query[key])
        .reduce((carry, key) => Object.assign(carry, { [key]: query[key] }), {})
    );

    this.props.history.push(redirectPath);
  }

  /**
   * @description Handle the searching of the text
   * @param {String} searchText The searched text
   * @param {Object} options The search options
   * @memberof SearchEngineCustom
   */
  searchText(searchText, options) {
    if (this.props.resultPage) {
      this.redirectToResultPage(searchText, options);
    }

    this.setState(
      {
        children: null,
        searchText,
        searchOptions: options,
        optionsToggled: false,
        searching: false,
        searchResult: (
          <Layout
            items={[
              {
                as: "ProductCategory/SiteComparator",
                props: { searchKey: searchText, searchOptions: options }
              },
              {
                as: "ProductCategory/SiteSelector",
                props: {
                  selectorType: PRODUCT_SELECTOR_TYPE_SEARCH_RESULT,
                  searchKey: searchText,
                  searchOptions: options
                }
              }
            ]}
          />
        )
      },
      this.renderSearchElements
    );
  }

  /**
   * @inheritdoc
   * @memberof SearchEngineCustom
   */
  handleCustomSearch(e, text, options) {
    this.searchText(text, options);
  }

  /**
   * @inheritdoc
   * @memberof SearchEngineCustom
   */
  handleCustomSearchTextChange(e, callback) {
    const searchKey = e.currentTarget.value;

    clearTimeout(this.suggestTimer);

    if (
      !searchKey ||
      searchKey.length < this.defaultSuggestOptions.minMatchCharLength
    ) {
      this.setState(
        { suggestResultVisibility: false },
        this.renderSearchElements
      );
      return;
    }

    this.suggestTimer = setTimeout(() => {
      this.props
        .searchSuggest(
          { searchKey, searchOptions: {}, filters: [] },
          this.props.siteConfig
        )
        .then(result => {
          this.props.searchSuggestSuccess(result);
          this.setState({ suggestResultVisibility: true }, () => {
            this.renderSearchElements(callback);
          });
        })
        .catch(this.props.searchSuggestFailure);
    }, 300);
  }

  /**
   * @inheritdoc
   * @memberof SearchEngineCustom
   */
  handleCustomSearchTextBlur(e) {
    if (
      e.relatedTarget &&
      e.relatedTarget.parentNode === this.refDropdownMenu.current
    ) {
      return;
    }

    setTimeout(() => {
      if (this.state.suggestResultVisibility) {
        this.setState({ suggestResultVisibility: false }, () => {
          this.renderSearchElements();
        });
      }
    }, 200);
  }

  /**
   * @inheritdoc
   * @memberof SearchEngineCustom
   */
  handleCustomSearchTextKeyDown(e) {
    super.handleCustomSearchTextKeyDown(e);

    if (["Enter", "Escape"].includes(e.key)) {
      e.preventDefault();

      this.setState({ suggestResultVisibility: false }, () => {
        this.renderSearchElements();
      });
    }

    if (["ArrowDown", "ArrowUp"].includes(e.key)) {
      e.preventDefault();

      const x = () => {
        if (this.refDropdownMenu.current) {
          this.refDropdownMenu.current.firstChild.focus();
        }

        if (!this.state.suggestResultVisibility) {
          if ("ArrowDown" === e.key) {
            this.setState(
              { suggestResultVisibility: true },
              this.renderSearchElements
            );
          }
        } else if ("ArrowUp" === e.key) {
          this.setState(
            { suggestResultVisibility: false },
            this.renderSearchElements
          );
        }
      };

      if (null === this.state.suggestResultVisibility) {
        this.handleCustomSearchTextChange(e, x);
      } else {
        x();
      }
    }
  }

  /**
   * @description Handle the suggestion dropdown item click
   * @param {ClickEvent} e The click event
   * @memberof SearchEngineCustom
   */
  handleSuggestionClick(e) {
    const searchText = e.currentTarget.innerText;

    this.setState({ searchText, suggestResultVisibility: false }, () => {
      this.refSearchControl.current.value = searchText;
      this.refSearchControl.current.focus();
      this.renderSearchElements();
    });
  }

  /**
   * @inheritdoc
   * @memberof SearchEngineCustom
   */
  renderSearchBoxControlRow() {
    if (!this.props.docked) {
      return super.renderSearchBoxControlRow();
    }

    const searchSuggestions = this.props.searchSuggestions.map((str, i) => {
      return (
        <Dropdown.Item
          key={i}
          eventKey={i}
          onClick={this.handleSuggestionClick}
          onKeyDown={e => {
            if ("Escape" === e.key) {
              this.setState({ suggestResultVisibility: false }, () => {
                this.refSearchControl.current.focus();
                this.renderSearchElements();
              });
            }
          }}
          onFocus={e => {
            e.currentTarget.parentNode.childNodes.forEach(el =>
              el.classList.remove("active")
            );
            e.currentTarget.classList.add("active");
          }}
          onBlur={e => {
            if (
              !e.relatedTarget ||
              !e.relatedTarget.classList.contains("dropdown-item")
            ) {
              this.setState(
                { suggestResultVisibility: false },
                this.renderSearchElements
              );
            }
          }}
          id={!i ? SE_TAG_SEARCHBOX_ONLY + "-first-suggestion" : null}
        >
          {str}
        </Dropdown.Item>
      );
    });

    const dropdownSearchSuggestions = searchSuggestions.length ? (
      <Dropdown show={this.state.suggestResultVisibility}>
        <Dropdown.Menu ref={this.refDropdownMenu}>
          {searchSuggestions}
        </Dropdown.Menu>
      </Dropdown>
    ) : null;

    const i18n = this.props.i18n;
    const placeholder = i18n ? i18n.placeholder || "" : null;

    return (
      <InputGroup size="lg">
        <FormControl
          id={SE_TAG_SEARCHBOX_ONLY}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={false}
          autoComplete={"off"}
          type="search"
          placeholder={placeholder}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={this.handleCustomSearchTextChange}
          onKeyDown={this.handleCustomSearchTextKeyDown}
          onFocus={this.handleCustomSearchTextFocus}
          onBlur={this.handleCustomSearchTextBlur}
          defaultValue={this.state.searchText}
          ref={this.refSearchControl}
        />
        {dropdownSearchSuggestions}
        <InputGroup.Append>
          <InputGroup.Text
            ref={this.refSearchBtn}
            title={this.getTitle()}
            onClick={e => {
              if (this.refSearchControl.current.value) {
                this.handleCustomSearch(
                  e,
                  this.refSearchControl.current.value,
                  this.state.searchOptions
                );
              }
            }}
          >
            <FontAwesomeIcon
              color="white"
              icon="search"
              role="search"
              badge={null}
              title={this.getTitle()}
            />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    );
  }
}

SearchEngineCustom.defaultProps = {
  ...SearchEngine.defaultProps,
  id: "search-engine-custom",
  assets: [
    {
      as: "script",
      comment: "Custom Search Engine",
      source: "/**/"
    }
  ],
  type: WIDGET_TYPE_INLINE,
  delay: 5000,
  // https://cse.google.com/cse/all > search engine > Search engine ID
  identity: null,
  debug: 0, // o=off, 1=on
  versionId: 6,
  docked: !matchBreakpoints([
    mediaBreakpoint.mobile,
    mediaBreakpoint.tabletPortrait
  ])
};

SearchEngineCustom.mapValueToProps = value => ({
  ...SearchEngine.mapValueToProps(value),
  disabled: "builtin" !== value.searchEngine.type
});

SearchEngineCustom.mapStateToProps = (state, ownProps) => {
  return { searchSuggestions: state.searchTextResult.searchSuggest || [] };
};

SearchEngineCustom.mapDispatchToProps = {
  searchSuggest,
  searchSuggestSuccess,
  searchSuggestFailure
};

export const ID = SearchEngineCustom.defaultProps.id;

export const DOCKED = SearchEngineCustom.defaultProps.docked;

export default connectHOCs(SearchEngineCustom, {
  withSite: true,
  withRouter: true,
  withConnect: true,
  withGraphQL: true
});
