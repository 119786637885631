import { withPlaceholder } from "@components-core/Placeholder";
import {
  EVENT_SEARCH_TOGGLED,
  PAGE_KEY_SEARCH_RESULT,
  SE_OPTION_RELEVANCE_ACCEPTABLE,
  SE_OPTION_RELEVANCE_HIGH,
  SE_OPTION_RELEVANCE_QUITE,
  SE_TAG_SEARCHBOX_ONLY,
  SE_TAG_SEARCHRESULTS_ONLY
} from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageBodyBS, PageHeaderBS, RootBS } from "@style-variables";
import { debug } from "@utils/debug";
import { createPortal } from "@utils/react";
import PropTypes from "prop-types";
import React from "react";
import {
  Alert,
  Col,
  Container,
  Dropdown,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Row
} from "react-bootstrap";
import GDPRAwareWidget from "../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../GDPR/utils";
import SearchEngineModal from "./Modal";

/**
 * @description Wrapper for programmable Search Engine plugin.
 *  Supports search input/results in same window, in separate windows,
 *  overlay and within same modal popup.
 *
 * @export
 * @class SearchEngine
 * @extends {GDPRAwareWidget}
 */
class SearchEngine extends GDPRAwareWidget {
  static SE_TAG_SEARCH = "search";
  static SE_TAG_SEARCHBOX = "searchbox";
  static SE_TAG_SEARCHBOX_ONLY = "searchbox-only";
  //static SE_TAG_SEARCHRESULTS_ONLY = "searchresults-only";

  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearchEvent = this.handleSearchEvent.bind(this);
    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    this.handleCustomSearch = this.handleCustomSearch.bind(this);
    this.handleCustomSearchTextChange =
      this.handleCustomSearchTextChange.bind(this);
    this.handleCustomSearchTextKeyDown =
      this.handleCustomSearchTextKeyDown.bind(this);
    this.handleCustomSearchTextFocus =
      this.handleCustomSearchTextFocus.bind(this);
    this.handleCustomSearchTextBlur =
      this.handleCustomSearchTextBlur.bind(this);

    this.handleCustomSearchOption = this.handleCustomSearchOption.bind(this);

    this.unregisterHistoryChange = null;

    this.state = {
      ...this.state,
      children: null,
      toggled: !props.toggleOnKeyCode,
      placeholder: props.placeholder,
      searchText: null,
      searchOptions: this.getDefaultSearchOptions(),
      optionsToggled: false,
      searching: false,
      searchResult: null
    };

    this.refSearchBtn = React.createRef();
    this.refSearchControl = React.createRef();
  }

  /**
   * @description Get the default search options
   * @returns {Object}
   * @memberof SearchEngine
   */
  getDefaultSearchOptions() {
    return {
      exactMatch: false,
      relevance: SE_OPTION_RELEVANCE_ACCEPTABLE
    };
  }

  /**
   * @description Checks whether the component supports running configuration
   * @returns {Boolean} Returns true if the component is correctly configured thus can be rendered, false otherwise
   * @memberof ExternalWidget
   */
  configSupported() {
    const config = {
      keyCode: !this.props.modal || this.props.toggleOnKeyCode,
      modal: this.props.modal || this.props.resultPage,
      resultPage: this.props.modal || this.props.resultPage
    };

    const err = Object.keys(config).filter(key => !config[key]);

    if (err.length) {
      debug(
        `Invalid configuration of ${
          this.constructor.name
        } component (${err.join(", ")})`,
        "warn"
      );
    }

    return !err.length && super.configSupported();
  }

  /**
   * @description Checkes whether the current page is a search-result page
   * @param {String} [path=null] When given test the given path otherwise the current page URL
   * @returns {Boolean}
   * @memberof SearchEngine
   */
  isSearchResultPage(path = null) {
    if (!this.props.resultPage) {
      return false;
    }

    const redirectPath = this.props.pathfinder.generate(this.props.resultPage);

    const _path = path || window.location.pathname;

    const href =
      -1 === _path.indexOf("?") ? _path + window.location.search : _path;

    return href.includes(redirectPath);
  }

  /**
   * @description Get the amount of ms to delay the component mounting
   * @returns {number}
   * @memberof SearchEngine
   */
  getDelay() {
    return this.isSearchResultPage() ? 0 : this.props.delay;
  }

  /**
   * @description Get the search element wrapper Id
   * @param {String} key A key corresponding to the the search element
   * @returns {String}
   * @memberof SearchEngine
   */
  getWrapperId(key) {
    return [this.props.predefined || this.props.id, key, this.props.identity]
      .filter(Boolean)
      .join("-");
  }

  componentDidMount() {
    super.componentDidMount();

    // mount the script only if not lazy-loading (see handleOnView)
    if (!this.props.disabled && this.configSupported()) {
      if (this.props.toggleOnKeyCode) {
        document.addEventListener("keydown", this.handleKeyDown);
      }

      // a custom event that will scroll the window to top when dispatched
      window.addEventListener(EVENT_SEARCH_TOGGLED, this.handleSearchEvent, {
        passive: true
      });

      this.unregisterHistoryChange = this.props.history.listen(
        this.handleHistoryChange
      );
    }
  }

  /**
   * @description Get whether the component is ready
   * @returns {Boolean}
   * @memberof SearchEngine
   */
  isReady() {
    return true;
  }

  /**
   * @description Get the search payload
   * @returns {Object}
   * @memberof SearchEngine
   */
  getSearchPayload() {
    const re = [
      this.props.queryParameterName,
      this.props.matchParameterName,
      this.props.relevanceParameterName
    ].reduce(
      (carry, key) =>
        Object.assign(carry, {
          [key]: new RegExp(`.*[?&]${key}=([^&]+).*`)
        }),
      {}
    );

    const query = decodeURIComponent(window.location.search);

    const searchText = re[this.props.queryParameterName].test(query)
      ? query.replace(re[this.props.queryParameterName], "$1")
      : null;

    const defaultSearchOptions = this.getDefaultSearchOptions();

    const searchOptions = {
      exactMatch: re[this.props.matchParameterName].test(query)
        ? query.replace(re[this.props.matchParameterName], "$1")
        : defaultSearchOptions.exactMatch,
      relevance: re[this.props.relevanceParameterName].test(query)
        ? query.replace(re[this.props.relevanceParameterName], "$1")
        : defaultSearchOptions.relevance
    };

    searchOptions.exactMatch = ["true", "1", true, 1].includes(
      searchOptions.exactMatch
    );

    return { searchText, searchOptions };
  }

  /**
   * @description Update the search state
   * @memberof SearchEngine
   */
  updateSearchState() {
    // either the searchbox or search result page is visible, otherwise don't bother
    if (this.isVisible()) {
      if (!this.state.searchText) {
        const { searchText, searchOptions } = this.getSearchPayload();

        const state = { searchText, searchOptions, toggled: true };

        if (searchText) {
          this.setState(state, () =>
            this.handleCustomSearch(null, searchText, searchOptions)
          );
        } else {
          const searchPage = this.isSearchResultPage(
            this.props.location.pathname
          );

          if (searchPage) {
            const i18n = this.props.i18n;
            state.searchResult = new Error(i18n.noMatch);
          }
          this.setState(state, this.renderSearchElements);
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.assetMounted && this.state.assetMounted) {
      // schedule a delayed post assets-mounting rendering
      const interval = setInterval(() => {
        if (this.isReady()) {
          clearInterval(interval);

          // trigger the search-flow
          this.updateSearchState();
        }
      }, 250);
    }
    // when returning to a previous rendered search-result page (eg. < Back)
    else {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        const searchPage = this.isSearchResultPage(
          this.props.location.pathname
        );

        if (searchPage) {
          if (this.state.children) {
            this.setState({ toggled: true }, this.renderSearchElements);
          } else {
            // trigger the search-flow
            this.updateSearchState();
          }
        } else {
          this.setState({ searchText: null }, () => {
            if (this.refSearchControl.current) {
              this.refSearchControl.current.value = "";
            }
          });
        }
      } else {
        if (!prevState.children && this.state.children) {
          this.renderSearchElements();
        }
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    if ("function" === typeof this.unregisterHistoryChange) {
      this.unregisterHistoryChange(this.handleHistoryChange);
    }

    window.removeEventListener(EVENT_SEARCH_TOGGLED, this.handleSearchEvent);

    if (this.props.toggleOnKeyCode) {
      document.removeEventListener("keydown", this.handleKeyDown);
    }
  }

  /**
   * @description Checks whether the component can render as modal popup
   * @returns {Boolean}
   * @memberof SearchEngine
   */
  supportsModal() {
    return this.props.modal && this.props.toggleOnKeyCode;
  }

  /**
   * @description Checks whether the component visibility is toggled
   * @returns {Boolean}
   * @memberof SearchEngine
   */
  isToggled() {
    return this.props.docked || this.state.toggled;
  }

  /**
   * @description Checks whether the component should be visible
   * @returns {Boolean}
   * @memberof SearchEngine
   */
  isVisible() {
    return (
      this.isToggled() ||
      !(this.supportsModal() || this.props.toggleOnKeyCode) ||
      this.isSearchResultPage()
    );
  }

  /**
   * @description Handle the event of component state toggle
   * @param {Boolean} toggled
   * @memberof SearchEngine
   */
  onToggled(toggled) {
    if (toggled) {
      this.renderSearchElements();
    } else {
      this.tearSearchElements();
    }
  }

  /**
   * @description Handle the custom search event. Should be overriden by child class.
   * @param {InputEvent} e The input event
   * @param {String} text The search text
   * @param {Object} options The search options
   * @memberof SearchEngine
   */
  handleCustomSearch(e, text, options) {}

  /**
   * @description Handle the custom search text change event. Should be overriden by child class.
   * @param {InputEvent} e The input event
   * @memberof SearchEngine
   */
  handleCustomSearchTextChange(e) {}

  /**
   * @description Handle the custom search text key down event
   * @param {KeyboardEvent} e The keyboard event
   * @memberof SearchEngine
   */
  handleCustomSearchTextKeyDown(e) {
    if ("Enter" === e.key) {
      this.refSearchBtn.current.click();
    }
  }

  /**
   * @description Handle the custom search text focus event
   * @param {FocusEvent} e The focus event
   * @memberof SearchEngine
   */
  handleCustomSearchTextFocus(e) {}

  /**
   * @description Handle the custom search text blur event
   * @param {FocusEvent} e The blur event
   * @memberof SearchEngine
   */
  handleCustomSearchTextBlur(e) {}

  /**
   * @description Handle the key down event
   * @param {KeyboardEvent} e The keyboard event
   * @memberof SearchEngine
   */
  handleKeyDown(e) {
    const modal = this.props.modal;

    if (!modal && this.isSearchResultPage()) {
      return;
    }

    let shouldToggle = false;
    let toggled = this.isToggled();

    if (this.props.closeOnEscape && toggled && e.code === "Escape") {
      toggled = !toggled;
    } else if ("object" === typeof this.props.toggleOnKeyCode) {
      const { ctrl, alt, shift, code } = this.props.toggleOnKeyCode;

      if (
        Boolean(ctrl) === e.ctrlKey &&
        Boolean(alt) === e.altKey &&
        Boolean(shift) === e.shiftKey &&
        code === e.code
      ) {
        shouldToggle = true;
        toggled = !toggled;
      }
    } else if (this.props.toggleOnKeyCode === e.code) {
      shouldToggle = true;
      toggled = !toggled;
    }

    if (toggled !== this.isToggled() || (modal && shouldToggle)) {
      this.setState(
        {
          toggled: toggled || (modal && shouldToggle),
          searchText: null,
          searchOptions: this.getDefaultSearchOptions(),
          optionsToggled: false,
          searching: false,
          searchResult: null
        },
        () => this.onToggled(toggled || (modal && shouldToggle))
      );
    }
  }

  /**
   * @description Handle the search custom event
   * @param {CustomEvent} e The custom event
   * @memberof SearchEngine
   */
  handleSearchEvent(e) {
    if (!this.props.toggleOnKeyCode) {
      return;
    }

    const evInit = {};

    if ("object" === typeof this.props.toggleOnKeyCode) {
      evInit.code = this.props.toggleOnKeyCode.code;
      evInit.ctrlKey = this.props.toggleOnKeyCode.ctrl;
      evInit.shiftKey = this.props.toggleOnKeyCode.shift;
      evInit.altKey = this.props.toggleOnKeyCode.alt;
    } else {
      evInit.code = this.props.toggleOnKeyCode;
    }

    this.handleKeyDown(new KeyboardEvent("keydown", evInit));
  }

  /**
   * @description Handle the window location history change events
   * @param {HashChangeEvent} e The event
   * @memberof SearchEngine
   */
  handleHistoryChange(e) {
    if (this.isReady() && !this.isSearchResultPage()) {
      this.setState({ toggled: false }, () => this.onToggled(this.isToggled()));
    }
  }

  /**
   * @description Handle the changes of search options
   * @param {String} relevance
   * @param {Event} event
   * @memberof SearchEngine
   */
  handleCustomSearchOption(relevance, event) {
    const searchOptions = { ...this.state.searchOptions };

    if (event) {
      searchOptions.exactMatch = event.currentTarget.checked;
    }
    if (relevance) {
      searchOptions.relevance = relevance;
    }

    this.setState({ searchOptions }, this.renderSearchElements);
  }

  /**
   * @description Tear the search elements
   * @memberof SearchEngine
   */
  tearSearchElements() {
    if (this.isReady()) {
      // tear-down existing search result
      [
        this.getWrapperId(SE_TAG_SEARCHBOX_ONLY),
        this.getWrapperId(SE_TAG_SEARCHRESULTS_ONLY)
      ].forEach(id => {
        const el = document.getElementById(id);
        if (el) {
          Array.from(el.children).forEach(child => el.removeChild(child));
        }
      });
    }
  }

  /**
   * @description Monitors the rendering of search-results container
   * @param {Function} callback A function to be called when the container is rendered to DOM
   * @memberof SearchEngine
   */
  observeSearchResultContainer(callback) {
    const pageBody = document.querySelector(`.${PageBodyBS}`);
    const config = { childList: true, subtree: true };

    const cb = function (mutationsList, observer) {
      const match = mutationsList
        .map(mutation => {
          if (mutation.type === "childList") {
            return Array.from(mutation.addedNodes).find(
              node => node.id === SE_TAG_SEARCHRESULTS_ONLY
            );
          }
          return false;
        })
        .filter(Boolean)
        .pop();

      if (match) {
        observer.disconnect();
        if ("function" === typeof callback) {
          callback(match);
        }
      }
    };

    const observer = new MutationObserver(cb);
    observer.observe(pageBody, config);
  }

  /**
   * @description Monitors the removal of page-header nodes
   * @param {Function} callback A function to be called when the container is removed from DOM
   * @memberof SearchEngine
   */
  observeRootContainer(callback) {
    const root = document.getElementById(RootBS);
    const config = { childList: true, subtree: true };

    const cb = function (mutationsList, observer) {
      const match = mutationsList
        .map(mutation => {
          if (mutation.type === "childList" && mutation.removedNodes.length) {
            return Array.from(mutation.removedNodes).find(
              node =>
                "string" === typeof node.className &&
                -1 !== node.className.indexOf(PageHeaderBS)
            );
          }
          return false;
        })
        .filter(Boolean)
        .pop();

      if (match) {
        observer.disconnect();
        if ("function" === typeof callback) {
          callback(match);
        }
      }
    };

    const observer = new MutationObserver(cb);
    observer.observe(root, config);
  }

  /**
   * @description Renders the search box control row
   * @returns {JSX}
   * @memberof SearchEngine
   */
  renderSearchBoxControlRow() {
    return (
      <Row className="py-3" key={0}>
        <Col>
          <FormLabel htmlFor={SE_TAG_SEARCHBOX_ONLY} srOnly>
            {this.getTitle()}
          </FormLabel>
          <InputGroup>
            {this.renderSearchBoxOptionsToggle()}
            <FormControl
              id={SE_TAG_SEARCHBOX_ONLY}
              placeholder={this.props.i18n.placeholder}
              type="search"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={this.props.autoFocus}
              onChange={this.handleCustomSearchTextChange}
              onKeyDown={this.handleCustomSearchTextKeyDown}
              onFocus={this.handleCustomSearchTextFocus}
              onBlur={this.handleCustomSearchTextBlur}
              defaultValue={this.state.searchText}
              ref={this.refSearchControl}
            />
            <InputGroup.Append>
              <InputGroup.Text
                ref={this.refSearchBtn}
                title={this.getTitle()}
                onClick={e => {
                  if (this.refSearchControl.current.value) {
                    this.handleCustomSearch(
                      e,
                      this.refSearchControl.current.value,
                      this.state.searchOptions
                    );
                  }
                }}
              >
                <FontAwesomeIcon
                  color="white"
                  icon="search"
                  role="search"
                  badge={null}
                  title={this.getTitle()}
                />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
    );
  }

  /**
   * @description Renders
   * @returns {JSX}
   * @memberof SearchEngine
   */
  renderSearchBoxOptionsToggle() {
    return this.props.showSearchOptions ? (
      <InputGroup.Prepend
        className="cursor-pointer"
        onClick={e =>
          this.setState(
            { optionsToggled: !this.state.optionsToggled },
            this.getRenderCallback
          )
        }
      >
        <InputGroup.Text>
          <FontAwesomeIcon
            icon={this.state.optionsToggled ? "chevron-up" : "chevron-down"}
          />
        </InputGroup.Text>
      </InputGroup.Prepend>
    ) : null;
  }

  /**
   * @description Renders the search box options row
   * @returns {JSX}
   * @memberof SearchEngine
   */
  renderSearchBoxOptionsRow() {
    const i18n = this.props.i18n;

    return (
      <Row>
        <Col xs="6" sm="3" lg="2">
          <FormGroup className="mb-3" controlId="chkExactMatch">
            <FormCheck
              type="checkbox"
              label={i18n.exactMatch}
              custom
              onChange={event =>
                this.handleCustomSearchOption(
                  this.state.searchOptions.relevance,
                  event
                )
              }
              value={this.state.searchOptions.exactMatch}
              defaultChecked={this.state.searchOptions.exactMatch}
            />
          </FormGroup>
        </Col>
        <Col
          xs="6"
          sm="9"
          lg="10"
          className={`text-right text-sm-left ${
            this.state.searchOptions.exactMatch ? "d-none" : ""
          }`}
        >
          <Dropdown
            size="sm"
            onSelect={eventKey => this.handleCustomSearchOption(eventKey)}
          >
            <Dropdown.Toggle variant="success" as="label">
              {i18n.relevance.title}
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight>
              <Dropdown.Item
                eventKey={SE_OPTION_RELEVANCE_HIGH}
                active={
                  SE_OPTION_RELEVANCE_HIGH ===
                  this.state.searchOptions.relevance
                }
              >
                {i18n.relevance.high}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={SE_OPTION_RELEVANCE_QUITE}
                active={
                  SE_OPTION_RELEVANCE_QUITE ===
                  this.state.searchOptions.relevance
                }
              >
                {i18n.relevance.quite}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={SE_OPTION_RELEVANCE_ACCEPTABLE}
                active={
                  SE_OPTION_RELEVANCE_ACCEPTABLE ===
                  this.state.searchOptions.relevance
                }
              >
                {i18n.relevance.acceptable}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    );
  }

  /**
   * @description Renders the search box control row
   * @param {JSX} content The rendered content. See renderSearchBox().
   * @returns {JSX}
   * @memberof SearchEngine
   */
  renderSearchBoxResultRow(content) {
    return (
      <Row key={1}>
        <Col>
          {withPlaceholder(this.state.searching, content, {
            lines: 3
          })}
        </Col>
      </Row>
    );
  }

  /**
   * @description Renders the search box
   * @param {Element} container The parent container
   * @param {JSX} content The rendered content
   * @returns {JSX}
   * @memberof SearchEngine
   */
  renderSearchBox(container, content) {
    const searchBoxRow = this.renderSearchBoxControlRow();

    const searchOptionsRow = this.state.optionsToggled
      ? this.renderSearchBoxOptionsRow()
      : null;

    const searchResultRow = this.props.modal
      ? this.renderSearchBoxResultRow(content)
      : null;

    return createPortal(
      <Container fluid key={0} className="px-0">
        {searchBoxRow}
        {searchOptionsRow}
        {searchResultRow}
      </Container>,
      container
    );
  }

  /**
   * @description Renders the search-results content
   * @param {Element} container The parent container
   * @param {JSX} content The content
   * @param {Function} callback A callback that receives the rendered content
   * @memberof SearchEngine
   */
  renderSearchResult(container, content, callback) {
    const child = withPlaceholder(this.state.searching, content, {
      lines: 3
    });

    if (container) {
      const resultPageSearchResultChild = createPortal(child, container);

      callback(resultPageSearchResultChild);
    } else {
      this.observeSearchResultContainer(match => {
        const resultPageSearchResultChild = createPortal(child, match);

        callback(resultPageSearchResultChild);
      });
    }
  }

  /**
   * @description A callback function that renders the search elements. Can be overriden by the child class.
   * @returns {Function}
   * @memberof SearchEngine
   */
  getRenderCallback(nestLevel = 0, callback) {
    const i18n = this.props.i18n;

    let searchResult = this.state.searchResult;

    if (searchResult instanceof Error) {
      searchResult = (
        <Alert variant="danger">
          {i18n.error + ": " + searchResult.message}
        </Alert>
      );
    } else if (Array.isArray(searchResult)) {
      if (!searchResult.length) {
        searchResult = <Alert variant="warning">{i18n.noMatch}</Alert>;
      }
    }

    const children = [];

    const containerId = this.props.modal
      ? this.getWrapperId()
      : this.getWrapperId(SE_TAG_SEARCHBOX_ONLY);

    const headerContainer = document.getElementById(containerId);

    if (headerContainer) {
      const headerSearchBoxChild = this.renderSearchBox(
        headerContainer,
        searchResult
      );

      children.push(headerSearchBoxChild);
    } else {
      debug(`No search engine wrapper "${containerId}" found`, "warn");
    }

    // this is necessary since the whole header might be re-rendered (which tears-up our portal-injected element)
    this.observeRootContainer(match => {
      // prevent invalid loop
      if (!nestLevel) {
        this.getRenderCallback(nestLevel + 1, callback);
      }
    });

    const pageSearchResultContainer = document.getElementById(
      SE_TAG_SEARCHRESULTS_ONLY
    );

    if (this.isSearchResultPage()) {
      this.renderSearchResult(
        pageSearchResultContainer,
        searchResult,
        child => {
          children.push(child);
          this.setState({ children });
        }
      );
    }

    this.setState({ children }, callback);
  }

  /**
   * @description Render the search elements
   * @memberof SearchEngine
   */
  renderSearchElements(callback) {
    if (this.isReady()) {
      this.setState({ placeholder: false }, () =>
        this.getRenderCallback(0, callback)
      );
    }
  }

  /**
   * @description Get the search engine modal dialog classname prefix
   * @returns {String}
   * @memberof SearchEngine
   */
  getSearchEngineModalClassPrefix() {
    return `se`;
  }

  /**
   * @description Get the search title
   * @returns {String}
   * @memberof SearchEngine
   */
  getTitle() {
    const i18n = this.props.i18n;

    return i18n
      ? (i18n.title || "").replace(/%HOSTNAME%/g, window.location.hostname)
      : null;
  }

  /**
   * @description Render the component within a modal popup
   * @param {Object} props The modal properties
   * @returns {GoogleSearchEngineModal}
   * @memberof SearchEngine
   */
  renderModal(props) {
    const i18n = this.props.i18n;

    return (
      <SearchEngineModal
        id={props.wrapperId}
        show={this.isToggled()}
        onHide={e => {
          this.setState({ toggled: !this.isToggled() });
        }}
        header={{ title: this.getTitle() }}
        buttons={{ close: { title: i18n ? i18n.buttons.close : null } }}
        seClassName={
          this.getSearchEngineModalClassPrefix() +
          "-" +
          SearchEngine.SE_TAG_SEARCHBOX_ONLY
        }
      >
        {props.children}
      </SearchEngineModal>
    );
  }

  /**
   * @inheritdoc
   * @memberof SearchEngine
   */
  renderAsDefault(props = {}) {
    if (!this.isVisible() /*|| !this.props.modal*/) {
      return null;
    }

    const modal = this.supportsModal();

    const wrapperId = this.getWrapperId();

    const children =
      modal && !(this.state.searchResult && this.props.resultPage)
        ? this.renderModal({
            ...props,
            children: [props.children, this.state.children],
            wrapperId
          })
        : withPlaceholder(
            this.state.placeholder,
            <Container id={wrapperId} fluid>
              {this.state.children}
            </Container>,
            { style: { minHeight: "4rem" } }
          );

    return this.isToggled()
      ? super.renderAsDefault({
          ...props,
          children: (
            <React.Fragment>
              {children}
              {props.children}
            </React.Fragment>
          )
        })
      : null;
  }
}

SearchEngine.mapValueToProps = value => {
  const result = {
    modal: value.searchEngine.modal,
    resultPage: value.searchEngine.resultPage ? PAGE_KEY_SEARCH_RESULT : null, // the route name of result page
    disabled: !value.searchEngine.type,
    adminOnly: value.searchEngine.adminOnly,
    i18n: {
      title: value.searchEngine.i18n.title,
      error: value.searchEngine.i18n.error,
      noMatch: value.searchEngine.i18n.noMatch,
      exactMatch: value.searchEngine.i18n.exactMatch,
      buttonClose: value.searchEngine.i18n.buttonClose,
      placeholder: value.searchEngine.i18n.placeholder,
      relevance: value.searchEngine.i18n.relevance
    }
  };

  if ("undefined" !== typeof value.i18n.components.SearchEngine.ADMIN_ONLY) {
    result.adminOnly = value.i18n.components.SearchEngine.ADMIN_ONLY;
  }

  return result;
};

SearchEngine.propTypes = {
  ...GDPRAwareWidget.propTypes,
  // Set modal + toggleOnKeyCode to display searchbox/result into a separate modal popop.
  modal: PropTypes.bool,
  // Set toggleOnKeyCode to show the searchbox on key press only.
  // Set toggleOnKeyCode to false/undefined to always show searchbox.
  toggleOnKeyCode: PropTypes.oneOfType([
    PropTypes.string, // eg. "KeyF"
    PropTypes.bool,
    PropTypes.shape({
      alt: PropTypes.bool, // see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/altKey
      ctrl: PropTypes.bool, // see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/ctrlKey
      shift: PropTypes.bool, // see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/shiftKey
      code: PropTypes.string // eg. "KeyF" (see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code)
    })
  ]),
  closeOnEscape: PropTypes.bool,
  i18n: PropTypes.shape({
    buttons: PropTypes.shape({
      close: PropTypes.string
    }),
    title: PropTypes.string
  }),
  placeholder: PropTypes.bool,
  autoFocus: PropTypes.bool,
  // Set resultPage to display the search results on a separate page, otherwise same page.
  // Ignored when modal=true
  resultPage: PropTypes.string, // make sure the page/route exist, otherwise 404!
  queryParameterName: PropTypes.string,
  docked: PropTypes.bool, // when true the searchbox is docked by some other component
  showSearchOptions: PropTypes.bool
};

SearchEngine.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "search-engine",
  assets: [
    {
      as: "script",
      comment: "Search Engine",
      source: ""
    }
  ],
  type: GDPRAwareWidget.WIDGET_TYPE_INLINE,
  headless: false,
  delay: 0,
  lazy: false,
  identity: null,
  // enbale lazy rendering on bot
  botDisabled: false,
  debug: 0, // o=off, 1=on
  versionId: 6,
  toggleOnKeyCode: { ctrl: true, alt: true, code: "KeyF" },
  closeOnEscape: true,
  placeholder: true,
  queryParameterName: "q",
  matchParameterName: "e",
  relevanceParameterName: "r",
  docked: false,
  showSearchOptions: false,
  autoFocus: true
});

export default SearchEngine;

export const ID = SearchEngine.defaultProps.id;
