import {
  /*  SEARCH_TEXT_FAILURE,
  SEARCH_TEXT_REQUEST,
  SEARCH_TEXT_SUCCESS*/
  SEARCH_SUGGEST_FAILURE,
  SEARCH_SUGGEST_REQUEST,
  SEARCH_SUGGEST_SUCCESS
} from "../actionTypesSearchEngine";

const searchTextResult = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    /*    case SEARCH_TEXT_REQUEST:
      return { ...newState, isFetching: true };
    case SEARCH_TEXT_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case SEARCH_TEXT_FAILURE:
      return { ...newState, error: action.error.message };*/
    case SEARCH_SUGGEST_REQUEST:
      return { ...newState, isFetching: true };
    case SEARCH_SUGGEST_SUCCESS:
      return {
        ...newState,
        searchSuggest: action.result,
        isFetching: false
      };
    case SEARCH_SUGGEST_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default { searchTextResult };
