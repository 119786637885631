/**
* Generated automatically at built-time (2024-12-10T09:05:28.884Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";
export default ({screen, siteContext})=>{
const helmet = mergeHelmet(siteContext.helmet, screen.helmet);
const pageId = "48a62869-cce1-40eb-b691-3ee2a69755bc";
return {[pageId]: {id: pageId,helmet,className: getComponentClassName(pageId),items: getScreenTemplates(screen)}};
}