import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";

/**
 * @description Search Engine Modal overlay component
 */
const SearchEngineModal = props => {
  const id = "se-modal-title";

  const title = props.header.title ? (
    <Modal.Title id={id}>{props.header.title}</Modal.Title>
  ) : null;

  const footer = props.buttons.close.title ? (
    <Modal.Footer>
      <Button onClick={props.onHide}>{props.buttons.close.title}</Button>
    </Modal.Footer>
  ) : null;

  return (
    <Modal
      size="xl"
      aria-labelledby={id}
      centered
      scrollable
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body id={props.id} className={props.seClassName}>
        {props.children}
      </Modal.Body>
      {footer}
    </Modal>
  );
};

SearchEngineModal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  header: PropTypes.shape({ title: PropTypes.string.isRequired }).isRequired,
  buttons: PropTypes.shape({
    close: PropTypes.shape({ title: PropTypes.string.isRequired }).isRequired
  }).isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  seClassName: PropTypes.string
};

export default SearchEngineModal;
