import { groupFiltersByName, getSuggestOptions } from "./utils";
import {
  /*  SEARCH_TEXT,
  SEARCH_TEXT_REQUEST,
  SEARCH_TEXT_SUCCESS,
  SEARCH_TEXT_FAILURE*/
  SEARCH_SUGGEST,
  SEARCH_SUGGEST_REQUEST,
  SEARCH_SUGGEST_SUCCESS,
  SEARCH_SUGGEST_FAILURE
} from "../actionTypesSearchEngine";
import { errorAddUnhandledException } from "./error";

/**
 * @description Requesting a search text
 * @param {Object} payload The product rating payload
 * @returns {Object} The action
 */
/*function searchTextRequest(payload) {
  return {
    type: SEARCH_TEXT_REQUEST,
    payload
  };
}*/

/**
 * @description Updating the store with the successfully search text result
 * @param {Object} result The post product rating result
 * @returns {Object} The action
 */
/*function searchTextSuccess(result) {
  return {
    type: SEARCH_TEXT_SUCCESS,
    result
  };
}*/

/**
 * @description Notifying the store about failing searching the text
 * @param {Error} error
 * @returns {Object}
 */
/*function searchTextFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: SEARCH_TEXT_FAILURE,
      error
    });
  };
}*/

/**
 * @description Submits the search text to the server
 * @param {Object} payload The searched text payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
// function searchText(payload, { siteId, i18n, graphqlClient }) {
//   return (dispatch, getState) => {
//     // inform the store we received a search-text action
//     dispatch({
//       type: SEARCH_TEXT,
//       payload
//     });

//     // inform the store we are sending a search-text request
//     dispatch(searchTextRequest(payload));

//     // return a promise to the result of requested action
//     return graphqlClient.gqlModule(
//       [
//         import(/* webpackChunkName: "site" */ "@graphql-query/search/searchText.gql"),
//         import(/* webpackChunkName: "site" */ "@graphql-query/relatedProductFragment.gql"),
//         import(/* webpackChunkName: "site" */ "@graphql-query/productCategorySummaryFragment.gql"),
//         import(/* webpackChunkName: "site" */ "@graphql-query/productImageFragment.gql"),
//         import(/* webpackChunkName: "site" */ "@graphql-query/productImageFieldsFragment.gql"),
//         import(/* webpackChunkName: "site" */ "@graphql-query/seoScoreFragment.gql")
//       ],
//       { siteId, ...payload },
//       data => data.searchText
//     );
//   };
// }

/**
 * @description Requesting a search word suggest
 * @param {Object} payload The product rating payload
 * @returns {Object} The action
 */
function searchSuggestRequest(payload) {
  return {
    type: SEARCH_SUGGEST_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully search word suggest result
 * @param {Object} result The word suggest result
 * @returns {Object} The action
 */
function searchSuggestSuccess(result) {
  return {
    type: SEARCH_SUGGEST_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing searching the text
 * @param {Error} error
 * @returns {Object}
 */
function searchSuggestFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: SEARCH_SUGGEST_FAILURE,
      error
    });
  };
}

/**
 * @description Submits the search word suggest to the server
 * @param {Object} payload The searched word suggest payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function searchSuggest(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received a search-suggest action
    dispatch({
      type: SEARCH_SUGGEST,
      payload
    });

    // inform the store we are sending a search-suggest request
    dispatch(searchSuggestRequest(payload));

    const filterByProducts = groupFiltersByName(payload.filters, graphqlClient);

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-query/search/searchSuggest.gql"
      ),
      {
        siteId,
        searchKey: payload.searchKey,
        filterBy: filterByProducts,
        searchOptions: getSuggestOptions(
          payload.searchOptions.exactMatch,
          payload.searchOptions.relevance
        )
      },
      data => data.searchSuggest
    );
  };
}

export {
  /*searchTextRequest, searchTextSuccess, searchTextFailure, searchText*/
  searchSuggestRequest,
  searchSuggestSuccess,
  searchSuggestFailure,
  searchSuggest
};
