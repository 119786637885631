/**
* Generated automatically at built-time (2024-12-10T09:05:28.954Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "utekok-black-friday",templateKey: "sites/99-a96d70f5-f501-4501-8490-2968a5192ef5"};