// !!! The value of these action types should be unique !!!

// ------------------------- SEARCH TEXT ----------------------------------
// export const SEARCH_TEXT = "SEARCH_TEXT"; // when searching for a text on server side
// export const SEARCH_TEXT_REQUEST = "SEARCH_TEXT_REQUEST"; // when searching for a text request is sent
// export const SEARCH_TEXT_SUCCESS = "SEARCH_TEXT_SUCCESS"; // when searching for a text request succeeds
// export const SEARCH_TEXT_FAILURE = "SEARCH_TEXT_FAILURE"; // when searching for a text request failed

export const SEARCH_SUGGEST = "SEARCH_SUGGEST"; // when searching for words suggestion on server side
export const SEARCH_SUGGEST_REQUEST = "SEARCH_SUGGEST_REQUEST"; // when searching for words suggestions request is sent
export const SEARCH_SUGGEST_SUCCESS = "SEARCH_SUGGEST_SUCCESS"; // when searching for words suggestions request succeeds
export const SEARCH_SUGGEST_FAILURE = "SEARCH_SUGGEST_FAILURE"; // when searching for words suggestions request failed