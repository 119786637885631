import { WIDGET_TYPE_URI } from "@components-core/ExternalWidget";
import { connectHOCs } from "@components-utils";
import { SE_TAG_SEARCHBOX_ONLY, SE_TAG_SEARCHRESULTS_ONLY } from "@constants";
import { debug } from "@utils/debug";
import SearchEngine from "../SearchEngine/SearchEngine";

/**
 * @description Wrapper for programmable Google Search Engine plugin.
 *  Supports search input/results in same window, in separate windows,
 *  overlay and within same modal popup.
 *
 * @export
 * @see https://support.google.com/programmable-search/answer/4513882?hl=en
 * @class GoogleSearchEngine
 * @extends {SearchEngine}
 */
class GoogleSearchEngine extends SearchEngine {
  /**
   * @inheritdoc
   * @memberof GoogleSearchEngine
   */
  isReady() {
    return super.isReady() && window.google;
  }

  getSearchElementWrapper() {
    const container = document.getElementById(
      this.getWrapperId(SE_TAG_SEARCHBOX_ONLY)
    );

    if (container) {
      return container.closest(".container-fluid");
    }

    return null;
  }

  tearSearchElements() {
    super.tearSearchElements();

    const wrapper = this.getSearchElementWrapper();

    if (wrapper) {
      wrapper.classList.remove("py-3");
    }
  }

  /**
   * @inheritdoc
   * @memberof GoogleSearchEngine
   */
  getRenderCallback() {
    const isResultPage = this.isSearchResultPage();

    this.setState({ searchResult: isResultPage });

    // https://developers.google.com/custom-search/docs/element#supported_attributes
    const attributes = {
      enableHistory: false,
      enableAutoComplete: true,
      enableOrderBy: true,
      linkTarget: "_top"
      // personalizedAds: !this.props.doNotTrack // this works with
    };

    if (this.props.resultPage) {
      attributes.queryParameterName = this.props.queryParameterName;
      attributes.resultsUrl = this.props.pathfinder.generate(
        this.props.resultPage
      );
    }

    let tag = GoogleSearchEngine.SE_TAG_SEARCHBOX_ONLY;

    if (this.props.resultPage) {
      if (isResultPage) {
        tag = GoogleSearchEngine.SE_TAG_SEARCHBOX;
      }
    } else {
      if (this.props.modal) {
        tag = GoogleSearchEngine.SE_TAG_SEARCH;
      }
    }

    // https://developers.google.com/custom-search/docs/element#cse-element
    const componentConfig = {
      div: this.getWrapperId(this.props.modal ? null : SE_TAG_SEARCHBOX_ONLY),
      tag,
      attributes
    };

    const opt_componentConfig = isResultPage
      ? {
          div: SE_TAG_SEARCHRESULTS_ONLY,
          tag: "searchresults"
        }
      : null;

    this.tearSearchElements();

    // https://developers.google.com/custom-search/docs/element#cse-element
    const gcse = window.google.search.cse.element.render(
      componentConfig,
      opt_componentConfig
    );

    if (gcse) {
      // a hack to make sure the GCSE elements have similar style with non-resultPage rendered elements
      if (this.props.resultPage) {
        const wrapper = this.getSearchElementWrapper();

        if (wrapper) {
          wrapper.classList.add("py-3");
        }
      }

      if (isResultPage) {
        let m;

        // prefill the searchbox with the query value
        if ((m = /[?&]q=([^&]*)/.exec(window.location.search)) !== null) {
          gcse.prefillQuery(decodeURI(m[1]));
        }
      } else {
        gcse.clearAllResults();
      }
    } else {
      debug(
        "Unexpected GCSE rendering result (see also Page/Header.js)",
        "warn"
      );
    }
  }

  /**
   * @inheritdoc
   * @memberof GoogleSearchEngine
   */
  getSearchEngineModalClassPrefix() {
    return `gcse`;
  }
}

GoogleSearchEngine.defaultProps = {
  ...SearchEngine.defaultProps,
  id: "google-search-engine",
  assets: [
    {
      as: "script",
      comment: "Google Search Engine",
      source: "https://cse.google.com/cse.js?cx=%IDENTITY%"
    }
  ],
  type: WIDGET_TYPE_URI,
  delay: 5000,
  // https://cse.google.com/cse/all > search engine > Search engine ID
  identity: null,
  debug: 0, // o=off, 1=on
  versionId: 6
  // see https://developers.google.com/custom-search/docs/element#supported_attributes
};

GoogleSearchEngine.mapValueToProps = value => ({
  ...SearchEngine.mapValueToProps(value),
  disabled: "google" !== value.searchEngine.type
});

export default connectHOCs(GoogleSearchEngine, {
  withSite: true,
  withRouter: true
});

export const ID = GoogleSearchEngine.defaultProps.id;

export const DOCKED = GoogleSearchEngine.defaultProps.docked;
