import { PAGE_KEY_SEARCH_RESULT, SE_TAG_SEARCHRESULTS_ONLY } from "@constants";
import React from "react";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/SearchResultTemplate";

const SearchResultTemplate = props => {
  const defaultTemplate = [
    {
      component: props => (
        <div className="container-fluid" id={SE_TAG_SEARCHRESULTS_ONLY} />
      ),
      props: {}
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

SearchResultTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_SEARCH_RESULT,
  templateKey: TEMPLATE_KEY
};

export default SearchResultTemplate;
