import { PAGE_KEY_SEARCH_RESULT } from "@constants";
import { SearchResultPageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = SearchResultPageBS;

  const defaultHelmet = {
    title: "search-result-page",
    meta: {
      name: {
        description: "sökresultat",
        // keywords: "x,y,z",
        robots: "noindex"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  return {
    [PAGE_KEY_SEARCH_RESULT]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
